export const IVA_PRODUCTS = [
  "Libros, revistas y periódicos.",
  "Venta de animales y vegetales no industrializados.",
  "Medicinas de patente.",
  "Joyería, oro, orfebrería, piezas ornamentales y artísticas.",
  "Invernaderos para cultivos.",
  "Alimentos procesados para mascotas.",
  "Chicles o gomas de mascar.",
  "Saborizantes, microencapsulados y aditivos alimentarios.",
  "Productos destinados a la alimentación, excepto por jugos y néctares.",
  "Venta de hielo y agua no gaseosa ni compuesta, en envases menores de 10 litros.",
  "Tractores para implementos agrícolas.",
  "Caviar, salmón ahumado y angulas.",
  "Fertilizante.",
  "Ixtle, lechuguilla y palma.",
  "Herbicidas, plaguicidas y fungicidas destinados a la agricultura o ganadería.",
]

export const IVA_SERVICES = [
  "Agricultura o ganadería.",
  "Pasteurización de leche.",
  "Molienda de granos (maíz, trigo, entre otros).",
  "Invernaderos hidropónicos.",
  "Despepite de algodón.",
  "Sacrificio de ganado y aves de corral.",
]
