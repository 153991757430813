<template>
  <b-modal
    id="add-bundle-modal"
    only-ok
    cancel-title="Cancelar"
    ok-title="Agregar"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    scrollable
    @ok="createBundles"
  >
    <validation-observer ref="refBundles">
      <p class="text-xl">Nuevos Paquetes</p>
      <div
        v-for="(bundle, index) in bundles"
        class="border p-2 rounded mt-2"
        :key="index"
      >
        <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
          <b-form-group label="Nombre del paquete">
            <b-form-input v-model="bundle.name" type="text" placeholder="Caja, Docena, etc." />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Cantidad"
          rules="required|numeric|min:1|min_value:2"
        >
          <b-form-group label="Cantidad de productos del paquete">
            <b-form-input
              v-model="bundle.quantity"
              type="number"
              placeholder="2"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" name="Código QR" rules="qrcode">
          <b-form-group label="Código QR (opcional)">
            <b-form-input
              v-model="bundle.qr_code"
              type="text"
              placeholder="1234567890"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" name="SKU" rules="sku">
          <b-form-group label="Código de barras (opcional)">
            <b-form-input
              v-model="bundle.sku"
              type="text"
              placeholder="ABC1234567"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </ValidationProvider>

        <b-button
          v-if="index > 0"
          class="mt-2"
          variant="outline-danger"
          @click="removeBundle(index)"
        >
          Eliminar bundle
        </b-button>
      </div>

      <b-button class="mt-2" variant="outline-secondary" @click="addBundle">
        Agregar bundle
      </b-button>
    </validation-observer>

    <template #modal-footer="{ cancel }">
      <b-button variant="success" @click="createBundles()"> Agregar </b-button>
      <b-button variant="outline-secondary" @click="cancel()">
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex"
import { ValidationProvider, ValidationObserver } from "vee-validate"

import messagesMixin from "@/@core/mixins/messagesMixin"
import { handleAPIErrors } from "@/@core/utils/fetch-utils"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      bundles: [
        {
          name: null,
          sku: null,
          qr_code: null,
          quantity: null,
          store_product_id: this.$route.params.store_product_id,
        },
      ],
    }
  },
  mixins: [messagesMixin],
  methods: {
    ...mapActions("bundles", ["createBulkBundle"]),

    addBundle() {
      this.bundles.push(this.buildBundle())
    },

    buildBundle() {
      return {
        name: null,
        sku: null,
        qr_code: null,
        quantity: null,
        store_product_id: this.$route.params.store_product_id,
      }
    },

    removeBundle(index) {
      this.bundles.splice(index, 1)
    },

    async createBundles() {
      const isValid = await this.validateBundles()
      if (this.bundles.length > 0 && !isValid) return

      this.$swal({
        title: "Cargando...",
        text: "Por favor espera mientras se crean los paquetes.",
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        onOpen: () => {
          this.$swal.showLoading()
        },
      })

      console.log(this.bundles)

      this.createBulkBundle({
        bundle: {
          bundles: this.bundles,
        },
      })
        .then(() => {
          this.successToast("Paquetes agregados")

          this.$emit("onSubmitted", this.bundles)

          this.bundles = [this.buildBundle()]
          this.$bvModal.hide("add-bundle-modal")
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response.data)
          this.errorToast(errors[0])
        })
        .finally(() => {
          this.$swal.close()
        })
    },

    validateBundles() {
      return new Promise((resolve, reject) => {
        this.$refs.refBundles.validate().then((success) => {
          if (success) {
            const uniqueValues = new Set()
            let isValid = true

            this.bundles.forEach((bundle) => {
              if (
                //uniqueValues.has(bundle.quantity) ||
                uniqueValues.has(bundle.qr_code) ||
                uniqueValues.has(bundle.sku)
              ) {
                isValid = false
              } else {
                //uniqueValues.add(bundle.quantity)

                if (bundle.qr_code) uniqueValues.add(bundle.qr_code)
                if (bundle.sku) uniqueValues.add(bundle.sku)
              }
            })

            if (!isValid) {
              this.errorToast("Código QR y SKU debe ser único")
                //this.errorToast("Cada cantidad, código QR y SKU debe ser único")
              resolve(false)
            } else {
              resolve(true)
            }
            resolve(true)
          } else {
            this.errorToast("Por favor, llena todos los campos")
            resolve(false)
          }
        })
      })
    },
  },
}
</script>
