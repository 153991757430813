<template>
  <div>
    <template v-if="storeProductData && storeProductFormData">
      <!-- GENERAL PRODUCT INFO -->
      <b-card>
        <h3 class="mb-2">Información del producto</h3>

        <store-product-info-card :product="storeProductData" />

        <!-- Variants -->
        <b-row
          v-if="
            storeProductData.store_product_variant_attributes &&
            storeProductData.store_product_variant_attributes.length > 0
          "
        >
          <b-col>
            <h5 class="mt-1 mr-1 mb-0">Variante:</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-badge
              v-for="(variant, n) in storeProductData
                .store_product_variant_attributes.variants"
              :key="`variant-${n}`"
              class="mr-1 mt-50 mb-1"
              pill
              variant="primary"
              size="sm"
            >
              {{ variant.variant_option_attributes.option_name }}
            </b-badge>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <b-tabs>
          <b-tab title="General" active>
            <validation-observer ref="refGeneralObserver">
              <div class="mt-2">
                <validation-provider
                  #default="{ errors }"
                  name="Precio"
                  :rules="{
                    required: true,
                    price_less_than_cost: storeProductFormData.unit_cost,
                  }"
                >
                  <p class="mb-50">Precio</p>
                  <b-input-group>
                    <b-form-input
                      id="precio"
                      v-model="storeProductFormData.unit_price"
                      autofocus
                      :state="errors.length > 0 ? false : true"
                      trim
                      type="number"
                      step="0.01"
                      placeholder=""
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>

              <div class="mt-2">
                <validation-provider
                  #default="{ errors }"
                  name="Costo"
                  :rules="{
                    required: true,
                    cost_less_than_price: storeProductFormData.unit_price,
                  }"
                >
                  <p class="mb-50">Costo</p>
                  <b-input-group>
                    <b-form-input
                      id="cost"
                      v-model="storeProductFormData.unit_cost"
                      :state="errors.length > 0 ? false : true"
                      trim
                      type="number"
                      step="0.01"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>

              <div class="mt-2">
                <validation-provider
                  #default="{ errors }"
                  name="Descripción"
                  :rules="{
                    required: false,
                    max: 255,
                  }"
                >
                  <p class="mb-50">Descripción</p>
                  <b-form-textarea
                    id="description"
                    v-model="storeProductFormData.description"
                    :state="errors.length > 0 ? false : true"
                    trim
                    type="text"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </validation-observer>
          </b-tab>
          <b-tab title="Inventario">
            <validation-observer ref="refInventoryObserver">
              <div
                class="d-flex justify-content-between align-items-center my-2"
              >
                <div>
                  <span> Inventario </span>
                  <b-button
                    variant="link"
                    v-b-tooltip.hover
                    title="Si deseas gestionar tu inventario, podrás tener acceso a reportes de tu inventario como valor del inventario a precio de venta y valuación de tu inventario con el método estandar, PEPS (Primero en Entrar, Primero en Salir) y promedio. Considera que no podrás realizar ventas del producto si la cantidad en existencia es cero y tienes configurado que sí deseas gestionarlo."
                    class="p-0 ml-1 align-middle"
                  >
                    <i
                      class="fas fa-question-circle"
                      style="font-size: 15px; color: #25d366"
                    ></i>
                  </b-button>
                </div>
                <b-button-group size="sm">
                  <b-button
                    :variant="
                      storeProductFormData.has_inventory
                        ? 'success'
                        : 'outline-secondary'
                    "
                    class="buttonWidth"
                    @click="storeProductFormData.has_inventory = true"
                  >
                    Gestionar
                  </b-button>
                  <b-button
                    :variant="
                      !storeProductFormData.has_inventory
                        ? 'danger'
                        : 'outline-secondary'
                    "
                    class="buttonWidth"
                    @click="storeProductFormData.has_inventory = false"
                  >
                    No
                  </b-button>
                </b-button-group>
              </div>
              <div
                v-if="storeProductFormData.has_inventory"
                class="border-top pt-2 pb-2"
              >
                <div class="mb-2">
                  <p class="font-weight-bolder">Existencias</p>
                  <span class="text-lg">
                    {{ formatInventory(storeProductFormData.inventory) }}
                  </span>
                </div>
                <div class="d-flex gap-2">
                  <div>
                    <span class="d-inline-flex mb-50">Agregar</span>
                    <b-form-input
                      v-model="unitsToAdd"
                      placeholder="Cantidad"
                      type="number"
                      size="sm"
                    />
                  </div>
                  <div>
                    <span class="d-inline-flex mb-50">Quitar</span>
                    <b-form-input
                      v-model="unitsToRemove"
                      placeholder="Cantidad"
                      type="number"
                      size="sm"
                    />
                  </div>
                  <div>
                    <span class="d-inline-flex mb-50">Perdidas</span>
                    <b-form-input
                      v-model="unitsLoss"
                      placeholder="Cantidad"
                      type="number"
                      size="sm"
                    />
                  </div>
                </div>
              </div>

              <!-- VALUACIÓN DE INVENTARIO -->
              <b-table
                v-if="storeProductFormData.has_inventory"
                ref="inventoryValuationTable"
                :items="inventoryValuation"
                responsive
                :fields="inventoryColumns"
                primary-key="label"
                show-empty
                small
                empty-text="No hay datos de inventario"
                class="text-center inventory-table"
              >
                <!-- Column: Description -->
                <template #cell(label)="data">
                  <strong>{{ data.item.label }}</strong>
                </template>

                <!-- Column: Unit cost -->
                <template #cell(unit_cost)="data">
                  <span>{{ data.item.unit_cost }}</span>
                </template>

                <!-- Column: Total cost value -->
                <template #cell(total_cost)="data">
                  <span>{{ data.item.total_cost }}</span>
                </template>
              </b-table>

              <div
                v-if="storeProductFormData.has_inventory"
                class="border-top pt-2 mt-2"
              >
                <p>
                  <strong>Inventario a precio de venta:</strong>
                  {{ inventoryAtCurrentPrice }}
                </p>
                <p>
                  <strong>Total de pérdidas registradas:</strong>
                  {{ storeProductFormData.inventory_loss }}
                </p>
              </div>
            </validation-observer>
          </b-tab>
          <b-tab title="Detalles">
            <validation-observer ref="refDetailsObserver">
              <div
                class="d-flex justify-content-between align-items-center my-2"
              >
                <div>
                  <span> ¿Sujeto a IVA? </span>
                  <b-button
                    variant="link"
                    v-b-tooltip.hover.html="getTooltipHTML"
                    class="p-0 ml-1 align-middle"
                  >
                    <i
                      class="fas fa-question-circle"
                      style="font-size: 15px; color: #25d366"
                    ></i>
                  </b-button>
                </div>
                <b-button-group size="sm">
                  <b-button
                    :variant="
                      storeProductFormData.has_tax
                        ? 'success'
                        : 'outline-secondary'
                    "
                    class="buttonWidth"
                    @click="storeProductFormData.has_tax = true"
                  >
                    Sí
                  </b-button>
                  <b-button
                    :variant="
                      !storeProductFormData.has_tax
                        ? 'danger'
                        : 'outline-secondary'
                    "
                    class="buttonWidth"
                    @click="setHasTax(false)"
                  >
                    No
                  </b-button>
                </b-button-group>
              </div>

              <div
                class="d-flex justify-content-between align-items-center my-2"
              >
                <div>
                  <span> ¿El precio incluye IVA? </span>
                  <b-button
                    variant="link"
                    v-b-tooltip.hover
                    title="¿El precio del prodcuto o servicio incluye IVA? Recuerda que debes incluir el IVA en el precio al público si facturas las ventas de productos."
                    class="p-0 ml-1 align-middle"
                  >
                    <i
                      class="fas fa-question-circle"
                      style="font-size: 15px; color: #25d366"
                    ></i>
                  </b-button>
                </div>
                <b-button-group size="sm">
                  <b-button
                    :variant="
                      storeProductFormData.price_includes_tax
                        ? 'success'
                        : 'outline-secondary'
                    "
                    class="buttonWidth"
                    @click="setPriceIncludesTax(true)"
                  >
                    Sí
                  </b-button>
                  <b-button
                    :variant="
                      !storeProductFormData.price_includes_tax
                        ? 'danger'
                        : 'outline-secondary'
                    "
                    class="buttonWidth"
                    @click="storeProductFormData.price_includes_tax = false"
                  >
                    No
                  </b-button>
                </b-button-group>
              </div>

              <div
                class="d-flex justify-content-between align-items-center my-2"
              >
                <div>
                  <span> Favorito </span>
                  <b-button
                    variant="link"
                    v-b-tooltip.hover
                    title="Si es favorito, el producto se agregará en la sección de favoritos en la Caja Registradora. De esta manera te será posible añadirlos al carrito sin necesidad de escanear o escribir ningún código o nombre de producto."
                    class="p-0 ml-1 align-middle"
                  >
                    <i
                      class="fas fa-question-circle"
                      style="font-size: 15px; color: #25d366"
                    ></i>
                  </b-button>
                </div>
                <b-button-group size="sm">
                  <b-button
                    :variant="
                      storeProductFormData.is_favorite
                        ? 'success'
                        : 'outline-secondary'
                    "
                    class="buttonWidth"
                    @click="storeProductFormData.is_favorite = true"
                  >
                    Favorito
                  </b-button>
                  <b-button
                    :variant="
                      !storeProductFormData.is_favorite
                        ? 'danger'
                        : 'outline-secondary'
                    "
                    class="buttonWidth"
                    @click="storeProductFormData.is_favorite = false"
                  >
                    No
                  </b-button>
                </b-button-group>
              </div>

              <div
                class="d-flex justify-content-between align-items-center my-2"
              >
                <div>
                  <span> Visibilidad </span>
                  <b-button
                    variant="link"
                    v-b-tooltip.hover
                    title="Si es público, cualquier persona podrá ver el producto en tu tienda en línea. Si es privado, solo podrán verlo aquellos con invitación."
                    class="p-0 ml-1 align-middle"
                  >
                    <i
                      class="fas fa-question-circle"
                      style="font-size: 15px; color: #25d366"
                    ></i>
                  </b-button>
                </div>
                <b-button-group size="sm">
                  <b-button
                    :variant="
                      !storeProductFormData.is_private
                        ? 'success'
                        : 'outline-secondary'
                    "
                    class="buttonWidth"
                    @click="storeProductFormData.is_private = false"
                  >
                    Publico
                  </b-button>
                  <b-button
                    :variant="
                      storeProductFormData.is_private
                        ? 'danger'
                        : 'outline-secondary'
                    "
                    class="buttonWidth"
                    @click="storeProductFormData.is_private = true"
                  >
                    Privado
                  </b-button>
                </b-button-group>
              </div>

              <div
                v-if="
                  storeProductFormData.product_attributes.product_type ==
                  'product'
                "
              >
                <validation-provider
                  #default="{ errors }"
                  name="UnitType"
                  rules="required"
                >
                  <p class="mb-50">¿Cómo se vende?</p>
                  <v-select
                    v-model="storeProductFormData.unit_code"
                    :options="unitCodeOptions"
                    :reduce="(val) => val.value"
                    label="name"
                    class="h-10"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </validation-observer>
          </b-tab>
        </b-tabs>

        <div class="w-100 d-flex justify-content-end mt-4">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="ghost"
            class="mr-2"
            @click="reset"
          >
            Cancelar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="onSubmit"
          >
            Guardar
          </b-button>
        </div>
      </b-card>

      <!-- STORE PRODUCT INFO -->

      <promos-table-new />

      <bundles-table
        :bundles="storeProductData.bundle_attributes"
        @onRemoveBundle="onRemoveBundle"
        @onAddBundle="onAddBundle"
        @onUpdateBundle="onUpdateBundle"
      />

      <edit-danger-zone :storeProduct="storeProductData" />
    </template>
  </div>
</template>

<script>
import store from "@/store"
import router from "@/router"
import formValidation from "@core/comp-functions/forms/form-validation"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { ref, watch, computed } from "@vue/composition-api"
import { mapActions } from "vuex"
import vSelect from "vue-select"

import StoreProductInfoCard from "@/views/products/product-view/StoreProductInfoCard.vue"
import PromosTableNew from "./PromosTableNew.vue"

import EditDangerZone from "@core/components/edit-store-products/edit-danger-zone.vue"
import BundlesTable from "@core/components/edit-store-products/bundles-table.vue"

import { required, alphaNum } from "@validations"
import messagesMixin from "@/@core/mixins/messagesMixin"
import { handleAPIErrors } from "@/utils/fetch-utils"
import { IVA_PRODUCTS, IVA_SERVICES } from "@/constants/store-product"

export default {
  components: {
    // Local Components
    StoreProductInfoCard,
    PromosTableNew,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,

    EditDangerZone,
    BundlesTable,
  },

  setup() {
    const storeProductData = ref(null)
    const storeProductFormData = ref(null)

    const initialStoreProductData = ref(null)
    const initialStoreProductFormData = ref(null)

    store
      .dispatch(
        "storeProducts/fetchStoreProduct",
        router.currentRoute.params.store_product_id
      )
      .then((response) => {
        storeProductData.value = structuredClone(response)
        storeProductFormData.value = structuredClone(response)
        initialStoreProductData.value = structuredClone(response)
        initialStoreProductFormData.value = structuredClone(response)
      })

    const formatCurrency = (value) => {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(value)
    }

    const formatInventory = (value) => {
      if (value == null) return "0.00"
      return Number(value).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    }

    const inventoryAtCurrentPrice = computed(() => {
      if (
        storeProductFormData.value &&
        storeProductFormData.value.inventory &&
        storeProductFormData.value.unit_price
      ) {
        return formatCurrency(
          storeProductFormData.value.inventory *
            storeProductFormData.value.unit_price
        )
      } else {
        return 0
      }
    })

    const inventoryValuation = computed(() => {
      if (storeProductFormData.value) {
        return [
          {
            label: "FIFO",
            unit_cost: formatCurrency(
              storeProductFormData.value.fifo_cost || 0
            ),
            total_cost: formatCurrency(
              storeProductFormData.value.fifo_cost_value || 0
            ),
          },
          {
            label: "Promedio",
            unit_cost: formatCurrency(
              storeProductFormData.value.average_cost || 0
            ),
            total_cost: formatCurrency(
              storeProductFormData.value.average_cost_value || 0
            ),
          },
          {
            label: "Actual",
            unit_cost: formatCurrency(
              storeProductFormData.value.unit_cost || 0
            ),
            total_cost: formatCurrency(
              storeProductFormData.value.standard_cost_value || 0
            ),
          },
        ]
      } else {
        return []
      }
    })

    const isUnitCostValid = computed(() => {
      if (storeProductFormData.value) {
        return (
          parseFloat(storeProductFormData.value.unit_cost) <=
          parseFloat(storeProductFormData.value.unit_price)
        )
      }
      return true // Si no hay datos, consideramos válido
    })

    function setNumberValues() {
      storeProductFormData.value.unit_price = Number(
        storeProductFormData.value.unit_price
      )
      storeProductFormData.value.unit_cost = Number(
        storeProductFormData.value.unit_cost
      )
      storeProductFormData.value.inventory = Number(
        storeProductFormData.value.inventory
      )
      storeProductFormData.value.inventory_loss = formatCurrency(
        Number(storeProductFormData.value.inventory_loss)
      )
    }

    function reset() {
      storeProductFormData.value = structuredClone(
        initialStoreProductFormData.value
      )
      setNumberValues()
    }

    watch(storeProductData, (newVal) => {
      if (newVal) {
        storeProductFormData.value = structuredClone(newVal)
        setNumberValues()
      }
    })

    return {
      storeProductData,
      storeProductFormData,
      inventoryValuation,
      inventoryAtCurrentPrice,
      isUnitCostValid,
      formatInventory,
      reset,
    }
  },
  mixins: [messagesMixin],
  data() {
    return {
      required,
      alphaNum,
      unitsToAdd: 0,
      unitsLoss: 0,
      unitsToRemove: 0,
      storeProductId: this.$route.params.store_product_id,
      bundle: null,
      isLoan: false,
      editMode: true,

      inventoryColumns: [
        {
          key: "label",
          label: "Descripción",
        },
        {
          key: "unit_cost",
          label: "Costo unitario",
        },
        {
          key: "total_cost",
          label: "Costo total",
        },
      ],

      unitCodeOptions: [
        {
          name: "Por pieza",
          value: "H87",
        },
        {
          name: "Por kilogramo",
          value: "KGM",
        },
        {
          name: "Por litro",
          value: "LTR",
        },
        {
          name: "Por gramo",
          value: "GRM",
        },
        {
          name: "Por miligramo",
          value: "MGM",
        },
        {
          name: "Por mililitro",
          value: "MLT",
        },
        {
          name: "Por tonelada de carga",
          value: "A75",
        },
      ],
    }
  },

  methods: {
    ...mapActions("storeProducts", ["editStoreProduct"]),
    ...mapActions("products", ["fetchProduct"]),
    ...mapActions("orders", ["dispatchOrder"]),
    ...mapActions("bundles", ["updateBundle"]),

    onAddBundle(bundles) {
      this.storeProductData.bundle_attributes = [
        ...this.storeProductData.bundle_attributes,
        ...bundles,
      ]
    },

    onRemoveBundle(bundle) {
      this.storeProductData.bundle_attributes =
        this.storeProductData.bundle_attributes.filter(
          (item) => item.id !== bundle.id
        )
    },

    onUpdateBundle(bundle) {
      this.storeProductData.bundle_attributes =
        this.storeProductData.bundle_attributes.map((item) => {
          if (item.id === bundle.id) {
            return bundle
          }
          return item
        })
    },

    getTooltipHTML() {
      const description = [
        "Los siguientes productos y servicios están exentos de IVA:",
      ]

      const descriptionList = `<p>${description.join("</p><p>")}</p>`
      const productList = `<ul>${IVA_PRODUCTS.map(
        (item) => `<li>${item}</li>`
      ).join("")}</ul>`
      const serviceList = `<ul>${IVA_SERVICES.map(
        (item) => `<li>${item}</li>`
      ).join("")}</ul>`

      return `<strong>${descriptionList}</strong> <strong>Productos:</strong> ${productList} <br /><strong>Servicios:</strong> ${serviceList}`
    },

    setHasTax(value) {
      this.storeProductFormData.has_tax = value
      this.storeProductFormData.price_includes_tax = value
    },

    setPriceIncludesTax(value) {
      this.storeProductFormData.price_includes_tax = value
      if (!this.storeProductFormData.has_tax && value) {
        this.storeProductFormData.price_includes_tax = false

        this.errorToast(
          "Inconsistencia!",
          "Has seleccionado que el precio incluye IVA, pero el producto no está sujeto a IVA. Por favor, modifica la configuración del producto sujeto a impuestos."
        )
      }
    },

    completeOrderDependingType() {
      if (
        this.unitsToAdd === 0 &&
        this.unitsToRemove === 0 &&
        this.unitsLoss === 0
      )
        return

      let type = null
      let promises = []
      if (this.unitsToAdd && this.unitsToAdd > 0) {
        type = "buy"
        promises.push(this.handleAddInventory(type, this.unitsToAdd))
      }
      if (this.unitsToRemove && this.unitsToRemove > 0) {
        type = "inventory_correction"
        promises.push(this.handleAddInventory(type, this.unitsToRemove))
      }
      if (this.unitsLoss && this.unitsLoss > 0) {
        type = "inventory_loss"
        promises.push(this.handleAddInventory(type, this.unitsLoss))
      }

      if (!promises.length) return

      return Promise.all(promises)
    },

    handleAddInventory(type, units) {
      this.dispatchOrder({
        order: {
          order_type: type,
          payment_type: "cash",
          is_loan: this.isLoan,
          store_id: this.$route.params.store_id,
          order_store_products_attributes: [
            {
              store_product_id: this.$route.params.store_product_id,
              units: units,
            },
          ],
          order_payments_attributes: [
            {
              payment_type: "cash",
              amount: units * this.storeProductFormData.unit_cost,
            },
          ],
        },
        orderType: type,
      }).catch((e) => {
        const errors = handleAPIErrors(e.response.data)
        this.errorToast(errors[0])
      })
    },

    validateObservers() {
      return Promise.all([
        this.$refs.refGeneralObserver.validate(),
        this.$refs.refInventoryObserver.validate(),
        this.$refs.refDetailsObserver.validate(),
      ]).then((values) => {
        return values.every((value) => value)
      })
    },

    async handleEditStore() {
      if (
        JSON.stringify(this.storeProductData) ===
        JSON.stringify(this.storeProductFormData)
      ) {
        return
      }

      this.storeProductFormData.tax_type = this.storeProductFormData.has_tax
        ? "IVA"
        : ""
      this.storeProductFormData.inventory = this.storeProductFormData
        .has_inventory
        ? this.storeProductFormData.inventory
        : 0

      await this.editStoreProduct(this.storeProductFormData)
    },

    async onSubmit() {
      const isValid = await this.validateObservers()
      if (!isValid) {
        this.errorToast(
          "Error",
          "Por favor, llena los campos requeridos correctamente"
        )
        return
      }

      try {
        await this.editStoreProduct(this.storeProductFormData)
      } catch (error) {
        const errors = handleAPIErrors(error.response.data)
        this.errorToast(errors[0])
      }

      try {
        await this.completeOrderDependingType()

        this.successToast(
          "Información actualizada",
          "El producto ha sido actualizado exitosamente"
        )
        this.$router.push({
          name: "store-products",
          params: { id: this.$route.params.store_id },
        })
      } catch {
        this.errorToast(
          "Error",
          "Ocurrió un error al actualizar el producto. Por favor, intenta de nuevo."
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.buttonWidth {
  width: 100px;
}
</style>
