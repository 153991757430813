<template>
  <b-modal id="edit-bundle-modal" no-close-on-backdrop scrollable>
    <validation-observer v-if="bundle" ref="refEditBundle">
      <p class="text-xl">Actualizar Paquete</p>
      <div class="border p-2 rounded mt-2">

        <ValidationProvider
          v-slot="{ errors }"
          name="Nombre"
          rules="required"
        >
          <b-form-group label="Nombre del paquete">
            <b-form-input
              v-model="bundle.name"
              type="text"
              placeholder="Caja, Docena, etc."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Cantidad"
          rules="required|numeric|min:1|min_value:2"
        >
          <b-form-group label="Cantidad de productos del paquete">
            <b-form-input
              v-model="bundle.quantity"
              type="number"
              placeholder="2"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" name="Código QR" rules="qrcode">
          <b-form-group label="Código QR (opcional)">
            <b-form-input
              v-model="bundle.qr_code"
              type="text"
              placeholder="1234567890"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" name="SKU" rules="sku">
          <b-form-group label="Código de barras (opcional)">
            <b-form-input
              v-model="bundle.sku"
              type="text"
              placeholder="ABC1234567"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </ValidationProvider>
      </div>
    </validation-observer>

    <template #modal-footer="{ cancel }">
      <b-button variant="success" @click="onSubmitted()"> Agregar </b-button>
      <b-button variant="outline-secondary" @click="cancel()">
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"

export default {
  props: {
    id: {
      type: String,
      default: "edit-bundle-modal",
    },
    bundle: {
      type: Object,
      default: null,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    onSubmitted() {
      this.$refs.refEditBundle.validate().then((success) => {
        if (success) {
          this.$emit("onSubmitted", this.bundle)
          this.$bvModal.hide("edit-bundle-modal")
        }
      })
    },
  },
}
</script>
