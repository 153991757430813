var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"p-2 d-flex justify-content-between"},[_c('h3',[_vm._v("Paquetes")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$bvModal.show('add-bundle-modal')}}},[_vm._v(" Agregar paquete ")])],1),_c('b-table',{staticClass:"text-center",attrs:{"items":_vm.bundles,"fields":[
      { key: 'name', label: 'Nombre' },
      { key: 'quantity', label: 'Cantidad' },
      { key: 'sku', label: 'Código de barras' },
      { key: 'qr_code', label: 'Código QR' },
      { key: 'actions', label: 'Acciones' } ],"responsive":"","primary-key":"sku","show-empty":"","small":"","empty-text":"No hay datos de paquetes"},scopedSlots:_vm._u([{key:"cell(quantity)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.quantity))])]}},{key:"cell(sku)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.sku))])]}},{key:"cell(qr_code)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.qr_code))])]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"right":"","variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fas fa-ellipsis-v"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function () {
              _vm.bundle = Object.assign({}, data.item)
              _vm.$bvModal.show('edit-bundle-modal')
            }}},[_vm._v("Editar")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.removeBundle(data.item)}}},[_vm._v("Eliminar")])],1)]}}])}),_c('add-bundle-modal',{on:{"onSubmitted":_vm.handleAddBundle}}),_c('edit-bundle-modal',{attrs:{"bundle":_vm.bundle},on:{"onSubmitted":_vm.handleUpdateBundle}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }