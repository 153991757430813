var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-bundle-modal","only-ok":"","cancel-title":"Cancelar","ok-title":"Agregar","cancel-variant":"outline-secondary","no-close-on-backdrop":"","scrollable":""},on:{"ok":_vm.createBundles},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.createBundles()}}},[_vm._v(" Agregar ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancelar ")])]}}])},[_c('validation-observer',{ref:"refBundles"},[_c('p',{staticClass:"text-xl"},[_vm._v("Nuevos Paquetes")]),_vm._l((_vm.bundles),function(bundle,index){return _c('div',{key:index,staticClass:"border p-2 rounded mt-2"},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre del paquete"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Caja, Docena, etc."},model:{value:(bundle.name),callback:function ($$v) {_vm.$set(bundle, "name", $$v)},expression:"bundle.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Cantidad","rules":"required|numeric|min:1|min_value:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cantidad de productos del paquete"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"2"},model:{value:(bundle.quantity),callback:function ($$v) {_vm.$set(bundle, "quantity", $$v)},expression:"bundle.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Código QR","rules":"qrcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Código QR (opcional)"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"1234567890"},model:{value:(bundle.qr_code),callback:function ($$v) {_vm.$set(bundle, "qr_code", $$v)},expression:"bundle.qr_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"SKU","rules":"sku"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Código de barras (opcional)"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"ABC1234567"},model:{value:(bundle.sku),callback:function ($$v) {_vm.$set(bundle, "sku", $$v)},expression:"bundle.sku"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(index > 0)?_c('b-button',{staticClass:"mt-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeBundle(index)}}},[_vm._v(" Eliminar bundle ")]):_vm._e()],1)}),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"outline-secondary"},on:{"click":_vm.addBundle}},[_vm._v(" Agregar bundle ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }