var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"edit-bundle-modal","no-close-on-backdrop":"","scrollable":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.onSubmitted()}}},[_vm._v(" Agregar ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancelar ")])]}}])},[(_vm.bundle)?_c('validation-observer',{ref:"refEditBundle"},[_c('p',{staticClass:"text-xl"},[_vm._v("Actualizar Paquete")]),_c('div',{staticClass:"border p-2 rounded mt-2"},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre del paquete"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Caja, Docena, etc."},model:{value:(_vm.bundle.name),callback:function ($$v) {_vm.$set(_vm.bundle, "name", $$v)},expression:"bundle.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4162765985)}),_c('ValidationProvider',{attrs:{"name":"Cantidad","rules":"required|numeric|min:1|min_value:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cantidad de productos del paquete"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"2"},model:{value:(_vm.bundle.quantity),callback:function ($$v) {_vm.$set(_vm.bundle, "quantity", $$v)},expression:"bundle.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2091942905)}),_c('ValidationProvider',{attrs:{"name":"Código QR","rules":"qrcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Código QR (opcional)"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"1234567890"},model:{value:(_vm.bundle.qr_code),callback:function ($$v) {_vm.$set(_vm.bundle, "qr_code", $$v)},expression:"bundle.qr_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2686926266)}),_c('ValidationProvider',{attrs:{"name":"SKU","rules":"sku"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Código de barras (opcional)"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"ABC1234567"},model:{value:(_vm.bundle.sku),callback:function ($$v) {_vm.$set(_vm.bundle, "sku", $$v)},expression:"bundle.sku"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2758322052)})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }