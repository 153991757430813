<template>
  <b-card no-body>
    <div class="p-2 d-flex justify-content-between">
      <h3>Paquetes</h3>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="$bvModal.show('add-bundle-modal')"
        variant="primary"
      >
        Agregar paquete
      </b-button>
    </div>

    <b-table
      :items="bundles"
      :fields="[
        { key: 'name', label: 'Nombre' },
        { key: 'quantity', label: 'Cantidad' },
        { key: 'sku', label: 'Código de barras' },
        { key: 'qr_code', label: 'Código QR' },
        { key: 'actions', label: 'Acciones' },
      ]"
      responsive
      primary-key="sku"
      show-empty
      small
      empty-text="No hay datos de paquetes"
      class="text-center"
    >
      <template #cell(quantity)="data">
        <span>{{ data.item.quantity }}</span>
      </template>
      <template #cell(sku)="data">
        <span>{{ data.item.sku }}</span>
      </template>
      <template #cell(qr_code)="data">
        <span>{{ data.item.qr_code }}</span>
      </template>
      <template #cell(actions)="data">
        <b-dropdown right variant="link" no-caret>
          <template #button-content>
            <i class="fas fa-ellipsis-v"></i>
          </template>
          <b-dropdown-item
            @click="
              () => {
                bundle = { ...data.item }
                $bvModal.show('edit-bundle-modal')
              }
            "
            >Editar</b-dropdown-item
          >
          <b-dropdown-item @click="removeBundle(data.item)"
            >Eliminar</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>

    <add-bundle-modal @onSubmitted="handleAddBundle" />
    <edit-bundle-modal :bundle="bundle" @onSubmitted="handleUpdateBundle" />
  </b-card>
</template>

<script>
import { mapActions } from "vuex"

import AddBundleModal from "@core/components/edit-store-products/add-bundle-modal.vue"
import EditBundleModal from "@core/components/edit-store-products/edit-bundle-modal.vue"

import messagesMixin from "@/@core/mixins/messagesMixin"
import { handleAPIErrors } from "@/utils/fetch-utils"

export default {
  components: {
    AddBundleModal,
    EditBundleModal,
  },
  props: {
    bundles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      bundle: {},
    }
  },
  mixins: [messagesMixin],
  methods: {
    ...mapActions("bundles", ["deleteBundle", "updateBundle"]),

    handleAddBundle(bundle) {
      this.$emit("onAddBundle", bundle)
    },

    handleUpdateBundle() {
      if (!this.bundle) {
        this.$bvModal.hide("edit-bundle-modal")
        return
      }

      this.updateBundle({ bundle: this.bundle })
        .then(() => {
          this.successToast("Paquete actualizado")
          this.$bvModal.hide("edit-bundle-modal")
          this.$emit("onUpdateBundle", this.bundle)
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response.data)
          this.errorToast(errors[0])
        })
    },

    removeBundle(bundle) {
      this.$swal({
        title: "¿Estás seguro?",
        text: "El paquete será eliminado ¿Estás seguro que deseas continuar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.successToast("Paquete eliminado")
          this.deleteBundle(bundle)

          this.$emit("onRemoveBundle", bundle)
        }
      })
    },
  },
}
</script>
