<template>
  <b-card>
    <div class="text-danger d-flex align-items-center">
      <feather-icon icon="AlertTriangleIcon" size="26" class="mr-1" />
      <span class="text-2xl bold"> Zona Peligrosa </span>
    </div>

    <div class="d-flex justify-content-between mt-2">
      <div>
        <p>Estado del producto</p>
        <p class="text-muted">
          Al eliminar el producto desaparecerá de la tienda
        </p>
      </div>

      <div>
        <b-form-checkbox @change="changeActiveStatus()" switch v-model="status">
          {{ status ? "Activo" : "Inactivo" }}
        </b-form-checkbox>
      </div>
    </div>

    <div class="d-flex mt-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        block
        variant="danger"
        class="d-flex align-items-center justify-content-center"
        @click="deleteStoreProduct()"
      >
        <feather-icon icon="TrashIcon" size="18" class="mr-50" />
        Eliminar producto
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { mapActions } from "vuex"

import messagesMixin from "@/@core/mixins/messagesMixin"
import { handleAPIErrors } from "@/@core/utils/fetch-utils"

export default {
  props: {
    storeProduct: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      status: this.storeProduct?.active_status,
    }
  },
  mixins: [messagesMixin],
  methods: {
    ...mapActions("storeProducts", ["editStoreProduct"]),

    deleteStoreProduct() {
      this.$swal({
        title: "¿Estás seguro?",
        text: "El producto será eliminado ¿Estás seguro que deseas continuar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, desactivar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeActiveStatus(false).then(() => {
            this.status = false
          })
        }
      })
    },

    changeActiveStatus(activeStatus) {
      return this.editStoreProduct({
        id: this.storeProduct.id,
        active_status: activeStatus ?? this.status,
      })
        .then(() => {
          this.successToast(
            "Información actualizada!",
            "El producto ha sido actualizado exitosamente"
          )
        })
        .catch((error) => {
          this.status = !this.status
          const errors = handleAPIErrors(error.response.data)
          this.errorToast(errors[0])
        })
    },
  },
}
</script>
